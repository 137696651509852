import { createTheme } from '@mui/material/styles';


const theme = createTheme({
    typography: {
        fontFamily: "'Montserrat', sans-serif",
        h1: {
            fontFamily: "'Libre Baskerville', serif",
            fontSize: '2.7rem',
            color: '#333',
        },
        h2: {
            fontFamily: "'Libre Baskerville', serif",
            fontSize: '2rem',
            color: '#444',
        },
        h3: {
            fontFamily: "'Libre Baskerville', serif",
            fontSize: '1.75rem',
            color: '#555',
        },
        h4: {
            fontFamily: "'Playfair Display', serif",
            fontWeight: 700,
            fontSize: '3rem',
            color: '#D4B9DB',
        },
        h6: {
            fontFamily: "'Libre Baskerville', serif",
            fontSize: '1.2rem',
            color: '#333',
        },
        body1: {
            fontFamily: "'Montserrat', sans-serif",
            fontSize: '1.1rem',
            color: '#575656',
        },
        body4: {
            fontFamily: "'Montserrat', sans-serif",
            fontSize: '1.1rem',
            color: '#575656',
        },
        body2: {
            fontFamily: "'Montserrat', sans-serif",
            fontSize: '1rem',
            color: '#333',
        },
        body3: {
            fontFamily: "'Montserrat', sans-serif",
            fontSize: '0.8rem',
            color: '#575656',
            lineHeight: 0,
        },
        
        // Add more custom typography styles as needed
    },
});

export default theme;
