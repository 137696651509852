import React from 'react';
import { Box, Grid, Card, Typography, CardMedia, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { useDrawer } from '../../context/DrawerContext';
import { isMobileDevice } from '../../utils/detectDevice'; 

const TitleTypography = styled(Typography)({
    color: '#D4B9DB',
    textAlign: 'center',
    marginBottom: '70px',
    fontFamily: "'Playfair Display', serif",
    fontSize: '3.2rem',
    fontWeight: 'bold',
    letterSpacing: '-0.05em',  // Adjust the value to decrease letter spacing
});

const PageContainer = styled(Box)(({ drawerOpen }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: `calc(100vw - ${drawerOpen ? 340 : 0}px)`, // Dynamically calculate width
    height: 'auto',
    margin: '0',
    paddingTop: '2rem',
    marginLeft: drawerOpen ? '340px' : '0', // Adjust padding to account for the drawer
    backgroundColor: '#ffffff',
    boxSizing: 'border-box',
    marginTop: '20px',
    marginBottom: '40px',
}));

const Overlay = styled(Box)({
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(217, 195, 222, 0.95)',
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const StyledCard = styled(Card)({
    width: '240px',
    height: '240px',
    display: 'flex',
    borderRadius: '50%',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
    margin: '-27px -7px',
    transition: 'transform 0.3s ease-in-out, border 0.3s ease-in-out, z-index 0.3s ease-in-out, box-shadow 0.1s ease-in-out',
    cursor: 'pointer',
    position: 'relative',
    overflow: 'hidden',
    border: '10px solid #D4B9DB',
    //boxShadow: '0 0 10px 5px rgba(217, 195, 222, 0.5)', // Translucent blur effect
    zIndex: 1, // Default z-index
    '&:hover': {
        transform: 'scale(1.2)', // Adjust scale to match border width
        border: 'none',
        boxShadow: 'none', // Remove the blur effect on hover
        zIndex: 1000, // Increase z-index on hover
        '.overlay': {
            opacity: 1,
        },
    },
});




const StyledCardMediaContainer = styled(Box)({
    width: '100%',
    height: '100%',
    overflow: 'hidden',
});

const StyledCardMedia = styled(CardMedia)({
    width: '100%',
    height: '100%',
    objectFit: 'fit',
    objectPosition: 'center',
});

const OverlayText = styled(Typography)({
    color: '#ffffff',
    fontFamily: "'Playfair Display', serif",
    fontWeight: 'bold',
    textAlign: 'center',
});

const HomePage = () => {
    const { drawerOpen } = useDrawer();
    const navigate = useNavigate();
    const isMobile = isMobileDevice();

    const handleCardClick = (path) => {
        navigate(path);
    };

    return (
        <PageContainer drawerOpen={drawerOpen}>
            <TitleTypography variant="h1" component="h2" gutterBottom>
                Discover Southwest Michigan Wine
            </TitleTypography>
            <Grid container spacing={0} justifyContent="center" width="100%">
                <Grid container item xs={12} justifyContent="center">
                    <Grid item>
                        <StyledCard onClick={() => handleCardClick('/grape-varieties')}>
                            <StyledCardMediaContainer>
                                <StyledCardMedia
                                    component="img"
                                    image="/assets/variety_images/riesling.jpg"
                                    alt="Grape Varieties"
                                />
                            </StyledCardMediaContainer>
                            <Overlay className="overlay">
                                <OverlayText variant="h4">
                                    Grape Varieties
                                </OverlayText>
                            </Overlay>
                        </StyledCard>
                    </Grid>
                    <Grid item>
                        <StyledCard onClick={() => handleCardClick('/map')}>
                            <StyledCardMediaContainer>
                                <StyledCardMedia
                                    component="img"
                                    image="/assets/map.png"
                                    alt="Map Preview"
                                />
                            </StyledCardMediaContainer>
                            <Overlay className="overlay">
                                <OverlayText variant="h4">
                                    Map
                                </OverlayText>
                            </Overlay>
                        </StyledCard>
                    </Grid>
                    <Grid item>
                        <StyledCard onClick={() => handleCardClick('/avas')}>
                            <StyledCardMediaContainer>
                                <StyledCardMedia
                                    component="img"
                                    image="/assets/content/dog.jpg"
                                    alt="AVAs"
                                />
                            </StyledCardMediaContainer>
                            <Overlay className="overlay">
                                <OverlayText variant="h4">
                                    AVAs
                                </OverlayText>
                            </Overlay>
                        </StyledCard>
                    </Grid>
                </Grid>
                <Grid container item xs={12} justifyContent="center">
                    <Grid item>
                        <Tooltip 
                            title="Coming Soon..." 
                            arrow 
                            PopperProps={{
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -50], // Adjust the second value to move the tooltip higher
                                        },
                                    },
                                ],
                            }}
                        >
                            <StyledCard>
                                <StyledCardMediaContainer>
                                    <StyledCardMedia
                                        component="img"
                                        image="/assets/content/gracia.png"
                                        alt="Must Try Wines"
                                    />
                                </StyledCardMediaContainer>
                                <Overlay className="overlay">
                                    <OverlayText variant="h4">
                                        Must Try Wines
                                    </OverlayText>
                                </Overlay>
                            </StyledCard>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <StyledCard onClick={() => handleCardClick('/wineries/crane\'s-winery')}>
                            <StyledCardMediaContainer>
                                <StyledCardMedia
                                    component="img"
                                    image="/assets/content/featured.png"
                                    alt="Featured Winery"
                                />
                            </StyledCardMediaContainer>
                            <Overlay className="overlay">
                                <OverlayText variant="h4">
                                    Featured Winery
                                </OverlayText>
                            </Overlay>
                        </StyledCard>
                    </Grid>
                </Grid>
                <Grid container item xs={12} justifyContent="center">
                <Grid item>
                    <StyledCard onClick={() => window.open('https://www.michigan.org/wineries', '_blank')}>
                        <StyledCardMediaContainer>
                            <StyledCardMedia
                                component="img"
                                image="/assets/pier2.jpg"
                                alt="More Michigan"
                            />
                        </StyledCardMediaContainer>
                        <Overlay className="overlay">
                            <OverlayText variant="h4">
                                More Michigan
                            </OverlayText>
                        </Overlay>
                    </StyledCard>
                </Grid>
                </Grid>
            </Grid>
        </PageContainer>
    );
};

export default HomePage;
