import React from 'react';
import { Typography } from '@mui/material';

const LmsMustTryBottles = () => {
    return (
        <Typography>
            {/* Must try bottles content goes here */}
            Section coming soon... We've been taking lots of notes the last few years and are excited to share what we enjoyed most.
        </Typography>
    );
};

export default LmsMustTryBottles;
