// src/context/DrawerContext.js
import React, { createContext, useContext, useState } from 'react';

const DrawerContext = createContext(null);

export const useDrawer = () => useContext(DrawerContext);

export const DrawerProvider = ({ children }) => {
    const [drawerOpen, setDrawerOpen] = useState(true);

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    return (
        <DrawerContext.Provider value={{ drawerOpen, toggleDrawer }}>
            {children}
        </DrawerContext.Provider>
    );
};
