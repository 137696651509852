import React from 'react';
import { styled } from '@mui/system';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const defaultDotSize = '15px';
const defaultDotColor = '#D4B9DB'; 

const CustomDot = styled(TimelineDot)(({ url, size, color }) => ({
    backgroundImage: url ? `url(${url})` : 'none',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: size || defaultDotSize,
    height: size || defaultDotSize,
    backgroundColor: url ? 'rgba(0, 0, 0, 0)' : defaultDotColor,
    border: url? 'none' : '10px', 
    borderColor: '#ffffff',
    boxShadow: 'none', // No shadow
  }));


const CustomConnector = styled(TimelineConnector)(({ color }) => ({
    backgroundColor: color || 'rgba(0, 0, 0, 0.5)',
    height: '20px', 
  }));
  
  const useStyles = () => ({
    paper: {
      padding: '16px 16px',
      borderRadius: '16px',
      width: '330px', 
      height: 'auto',
      boxShadow: '0px 2px 2px rgba(0, 0, 0, 0)',
      fontFamily: "'Montserrat', sans-serif",
    },
    verticallyCenterContent: {
      margin: 'auto 0',
    },
  });
  
  export default function CustomizedTimeline() {
    const classes = useStyles();
  
    return (
      <Timeline align="alternate">
        <TimelineItem>
          <TimelineOppositeContent
            style={classes.verticallyCenterContent}
            align="left"
            variant="body1"
            color="textSecondary"
          >
            1868
          </TimelineOppositeContent>
          <TimelineSeparator>
            <CustomConnector color='defaultDotColor' />
            <CustomDot/>
            <CustomConnector color='defaultDotColor' />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} style={classes.paper}>
              <Typography variant="h6" component="h1">
                Vineyards in Lawton
              </Typography>
              <Typography variant="body2" color="textSecondary">
              A.B. Jones plants vineyards in Lawton, attracting Welch’s to the region during Prohibition and marking a pivotal milestone for the industry.
              </Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent
            style={classes.verticallyCenterContent}
            variant="body1"
            color="textSecondary"
          >
            Late 1800s
          </TimelineOppositeContent>
          <TimelineSeparator>
            <CustomConnector color='defaultDotColor' />
            <CustomDot />
            <CustomConnector color='defaultDotColor' />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} style={classes.paper}>
              <Typography variant="h6" component="h1">
                Third in Production
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Michigan ranks third in wine production behind California and New York.
              </Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent
            style={classes.verticallyCenterContent}
            variant="body1"
            color="textSecondary"
          >
            1918
          </TimelineOppositeContent>
          <TimelineSeparator>
            <CustomConnector color='defaultDotColor' />
            <CustomDot />
            <CustomConnector color='defaultDotColor' />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} style={classes.paper}>
              <Typography variant="h6" component="h1">
                Alcohol Ban
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Michigan enacts a ban on alcohol, ceasing the legal wine industry.
              </Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent
            style={classes.verticallyCenterContent}
            variant="body1"
            color="textSecondary"
          >
            1920
          </TimelineOppositeContent>
          <TimelineSeparator>
            <CustomConnector color='defaultDotColor' />
            <CustomDot />
            <CustomConnector color='defaultDotColor' />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} style={classes.paper}>
              <Typography variant="h6" component="h1">
                Prohibition
              </Typography>
              <Typography variant="body2" color="textSecondary">
                National Prohibition bans alcohol across the United States.
              </Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent
            style={classes.verticallyCenterContent}
            variant="body1"
            color="textSecondary"
          >
            1933
          </TimelineOppositeContent>
          <TimelineSeparator>
            <CustomConnector color='defaultDotColor' />
            <CustomDot />
            <CustomConnector color='defaultDotColor' />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} style={classes.paper}>
              <Typography variant="h6" component="h1">
                Repeal of Prohibition
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Michigan is the first state to ratify the repeal, allowing wineries to reopen.
              </Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent
            style={classes.verticallyCenterContent}
            variant="body1"
            color="textSecondary"
          >
            1934
          </TimelineOppositeContent>
          <TimelineSeparator>
            <CustomConnector color='defaultDotColor' />
            <CustomDot />
            <CustomConnector color='defaultDotColor' />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} style={classes.paper}>
              <Typography variant="h6" component="h1">
                St. Julian Winery
              </Typography>
              <Typography variant="body2" color="textSecondary">
              Meconi Wine Co moves to Paw Paw, becoming the Italian Wine Co. and then St. Julian Winery, now Michigan’s oldest continuously operating winery.
              </Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent
            style={classes.verticallyCenterContent}
            variant="body1"
            color="textSecondary"
          >
            1938
          </TimelineOppositeContent>
          <TimelineSeparator>
            <CustomConnector color='defaultDotColor' />
            <CustomDot />
            <CustomConnector color='defaultDotColor' />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} style={classes.paper}>
              <Typography variant="h6" component="h1">
                Michigan Wine Institute
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Michigan Wine Institute is established to support the wine industry.
              </Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent
            style={classes.verticallyCenterContent}
            variant="body1"
            color="textSecondary"
          >
            1940s
          </TimelineOppositeContent>
          <TimelineSeparator>
            <CustomConnector color='defaultDotColor' />
            <CustomDot />
            <CustomConnector color='defaultDotColor' />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} style={classes.paper}>
              <Typography variant="h6" component="h1">
                Wine Industry Moves West
              </Typography>
              <Typography variant="body2" color="textSecondary">
              A strong wine industry emerges in Southwest Michigan, with most wineries relocating near Lake Michigan vineyards. Consumers begin to shift towards dry wines.
              </Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent
            style={classes.verticallyCenterContent}
            variant="body1"
            color="textSecondary"
          >
            1950
          </TimelineOppositeContent>
          <TimelineSeparator>
            <CustomConnector color='defaultDotColor' />
            <CustomDot />
            <CustomConnector color='defaultDotColor' />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} style={classes.paper}>
              <Typography variant="h6" component="h1">
                Licensing Fee Eliminated
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Michigan eliminates a $5,000 licensing fee, boosting wine production by enabling sales in grocery stores.
              </Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent
            style={classes.verticallyCenterContent}
            variant="body1"
            color="textSecondary"
          >
            1962
          </TimelineOppositeContent>
          <TimelineSeparator>
            <CustomConnector color='defaultDotColor' />
            <CustomDot />
            <CustomConnector color='defaultDotColor' />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} style={classes.paper}>
              <Typography variant="h6" component="h1">
                First Dry Wine
              </Typography>
              <Typography variant="body2" color="textSecondary">
              Bronte Champagne and Wine Co. produces Michigan’s first dry wine with Baco Noir, a French-American hybrid grape, in Hartford.
              </Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent
            style={classes.verticallyCenterContent}
            variant="body1"
            color="textSecondary"
          >
            1960s
          </TimelineOppositeContent>
          <TimelineSeparator>
            <CustomConnector color='defaultDotColor' />
            <CustomDot />
            <CustomConnector color='defaultDotColor' />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} style={classes.paper}>
              <Typography variant="h6" component="h1">
                Cold Duck Innovation
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Bronte bottles Cold Duck, later inspiring the famous André sparkling wine.
              </Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent
            style={classes.verticallyCenterContent}
            variant="body1"
            color="textSecondary"
          >
            1972
          </TimelineOppositeContent>
          <TimelineSeparator>
            <CustomConnector color='defaultDotColor' />
            <CustomDot />
            <CustomConnector color='defaultDotColor' />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} style={classes.paper}>
              <Typography variant="h6" component="h1">
                State Law Change
              </Typography>
              <Typography variant="body2" color="textSecondary">
                A tax-break law change requiring wineries pay higher amounts to growers causes financial strain, shutting down many wineries.
              </Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent
            style={classes.verticallyCenterContent}
            variant="body1"
            color="textSecondary"
          >
            1981
          </TimelineOppositeContent>
          <TimelineSeparator>
            <CustomConnector color='defaultDotColor' />
            <CustomDot url="/assets/fennvillecity.png" />
            <CustomConnector color='defaultDotColor' />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} style={classes.paper}>
              <Typography variant="h6" component="h1">
                Fennville AVA & Bob Hope
              </Typography>
              <Typography variant="body2" color="textSecondary">Fennville becomes an American Viticultural Area. Bob Hope loves Tabor Hill’s Vidal Blanc demi-sec, later ordering 80 cases for his 80th birthday.</Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent
            style={classes.verticallyCenterContent}
            variant="body1"
            color="textSecondary"
          >
            1983
          </TimelineOppositeContent>
          <TimelineSeparator>
            <CustomConnector color='defaultDotColor' />
            <CustomDot url="/assets/lms_logo.png" />
            <CustomConnector color='defaultDotColor' />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} style={classes.paper}>
              <Typography variant="h6" component="h1">
                Lake Michigan Shore AVA
              </Typography>
              <Typography variant="body2" color="textSecondary">Lake Michigan Shore becomes an American Viticultural Area.</Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent
            style={classes.verticallyCenterContent}
            variant="body1"
            color="textSecondary"
          >
            1985
          </TimelineOppositeContent>
          <TimelineSeparator>
            <CustomConnector color='defaultDotColor' />
            <CustomDot />
            <CustomConnector color='defaultDotColor' />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} style={classes.paper}>
              <Typography variant="h6" component="h1">
                Michigan Wine Council
              </Typography>
              <Typography variant="body2" color="textSecondary">The Michigan Grape and Wine Industry Council is established, later becoming the Michigan Craft Beverage Council.</Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent
            style={classes.verticallyCenterContent}
            variant="body1"
            color="textSecondary"
          >
            1990s
          </TimelineOppositeContent>
          <TimelineSeparator>
            <CustomConnector color='defaultDotColor' />
            <CustomDot />
            <CustomConnector color='defaultDotColor' />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} style={classes.paper}>
              <Typography variant="h6" component="h1">
                Wineries Expansion
              </Typography>
              <Typography variant="body2" color="textSecondary">Thirteen new wineries open across Michigan.</Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent
            style={classes.verticallyCenterContent}
            variant="body1"
            color="textSecondary"
          >
            2000s
          </TimelineOppositeContent>
          <TimelineSeparator>
            <CustomConnector color='defaultDotColor' />
            <CustomDot />
            <CustomConnector color='defaultDotColor' />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} style={classes.paper}>
              <Typography variant="h6" component="h1">
                New Varieties and Styles
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Numerous new wineries open, exploring diverse varieties, styles, and viticulture practices.
              </Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    );
  }