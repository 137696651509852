import React, { useState, useEffect } from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';
import CustomizedTimeline from './LmsTimeline';
import lmsContent from './lms_content.json';

// Custom scrollbar styles
const ScrollbarStyles = styled('div')({
    '&::-webkit-scrollbar': {
        width: '8px',
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: '#f5f2eb', // Match the background color
        borderRadius: '16px',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#b8a990',
        borderRadius: '16px',
    },
});

const StyledAccordion = styled(Accordion)({
    maxWidth: '900px',
    width: '100%',
    margin: '0 auto',
    marginTop: '35px',
    borderRadius: '16px', // Apply border radius to the entire accordion
    backgroundColor: 'transparent', // Ensure background remains transparent
    overflow: 'hidden',
    '& .MuiAccordionSummary-root': {
        borderRadius: '16px', // Rounded corners for the summary
        backgroundColor: '#fff', // Set closed accordion background to white
        '&.Mui-expanded': {
            borderBottomLeftRadius: '16px', // Remove bottom border radius when expanded
            borderBottomRightRadius: '16px', // Remove bottom border radius when expanded
        },
    },
    '& .MuiAccordionDetails-root': {
        padding: 0,
        backgroundColor: '#f5f2eb', // Set the content window background to beige
        borderRadius: '16px', // Rounded corners for the content area
    },
});

const AccordionContentWrapper = styled(ScrollbarStyles)({
    padding: '16px',
    backgroundColor: '#f5f2eb', // Set the content window background to beige
    overflowY: 'auto', // Enable scrolling for long content
    overflowX: 'hidden', // Disable horizontal scrolling
    maxHeight: '400px', 
    marginTop: '8px',
});

const LmsHistory = () => {
    const [content, setContent] = useState('');

    useEffect(() => {
        fetch('/register.html') // Ensure this is the correct path relative to the public directory
            .then(response => response.text())
            .then(data => {
                setContent(data);
            })
            .catch(error => {
                console.error('Error fetching the content:', error);
            });
    }, []);

    // Split the content into paragraphs based on "\n\n"
    const paragraphs = lmsContent.history.split('\n\n');

    return (
        <Box sx={{ 
            marginTop: '1rem', 
            width: '97%', 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            alignItems: 'flex-start' 
        }}>
            <Box sx={{ 
                flex: 0, 
                maxWidth: { xs: '100%', md: '700px' }, 
                marginRight: { md: '16px' }, 
                marginLeft: { xs: '0', md: '-20px' }, 
                marginTop: { xs: '0', md: '-90px' } 
            }}>
                <CustomizedTimeline />
            </Box>
            <Box sx={{ 
                flex: 1, 
                maxWidth: { xs: '100%', md: '800px' }, 
                marginTop: { xs: '16px', md: '0' } 
            }}>
                {paragraphs.map((paragraph, index) => (
                    <Typography key={index} variant="body1" paragraph>
                        {paragraph}
                    </Typography>
                ))}
                <StyledAccordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                            display: 'flex',
                            justifyContent: 'center', 
                            backgroundColor: '#fff',
                            borderRadius: '16px',
                            padding: { xs: '8px 16px', md: '16px 32px' }
                        }}
                    >
                        <Typography variant="h6" align="center" sx={{ fontSize: { xs: '16px', md: '20px' } }}>
                            Read the official Federal Register creating the AVA on October 13, 1983
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <AccordionContentWrapper>
                            <Typography id="accordion-description" variant="body1">
                                {content ? (
                                    <div dangerouslySetInnerHTML={{ __html: content }} />
                                ) : (
                                    'Loading content...'
                                )}
                            </Typography>
                        </AccordionContentWrapper>
                    </AccordionDetails>
                </StyledAccordion>
            </Box>
        </Box>
    );
};

export default LmsHistory;
