import React from 'react';
import { Grid } from '@mui/material';
import WineryCard from '../../components/WineryCard';
import wineryData from '../../data/wineryData.json'; // Import winery data JSON file

const LmsWineries = () => {
    // Filter wineries by AVA "Lake Michigan Shore"
    const filteredWineries = wineryData.filter(winery => winery.ava === 'Lake Michigan Shore');

    return (
        <Grid container spacing={2}>
            {filteredWineries.map((winery, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                    <WineryCard
                        name={winery.name}
                        location={winery.address}
                        //description={winery.description}
                        image={winery.image}
                        small={false} // Ensure the card is a normal card
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default LmsWineries;
