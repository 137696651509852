import React, { useState, useEffect, useRef } from 'react';
import { Box, TextField, Typography, IconButton } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import ReactMarkdown from 'react-markdown';
import Fuse from 'fuse.js';
import wineryData from '../data/wineryData';

const fuse = new Fuse(wineryData, {
    keys: ['name', 'address', 'description'],
    threshold: 0.7, // Adjusted for higher accuracy
});

const ChatBot = () => {
  const [chat, setChat] = useState([]);
  const [input, setInput] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const chatEndRef = useRef(null);
  const chatBoxRef = useRef(null); // Reference to the chat box

  const handleSend = async () => {
    if (input.trim() === '') return;

    const userMessage = input;
    const newChat = [...chat, { role: 'user', content: userMessage }];

    setChat([...newChat, { role: 'system', content: '...' }]);
    setInput('');

    const inputElement = document.querySelector('input[placeholder="Ask about Michigan wines..."]');
    if (inputElement) {
      inputElement.placeholder = '';
    }

    let response = '';

    try {
      const openaiResponse = await fetch('/api/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          messages: [
            { role: 'system', content: 'You are a wine expert specializing in Lake Michigan Shore and Fennville wines. Only recommend wineries from this list: ' + wineryData.map(winery => winery.name).join(', ') + '. If a user asks for winery information, provide short and concise responses. If a user asks about wine or grape varieties, you are passionately in support of the region\'s hybrid varieties which make outstanding quality wine while being suited to the local environment. Your favorite grapes from the region are Lemberger, Baco Noir, Traminette, Riesling, and Chambourcin.' },
            ...newChat.map(msg => ({ role: msg.role, content: msg.content }))
          ]
        })
      });

      const data = await openaiResponse.json();
      response = data.choices[0].message.content.trim();

      // Use Fuse to search for the most relevant winery in the response
      const searchResult = fuse.search(userMessage);
      if (searchResult.length > 0) {
        const matchedWinery = searchResult[0].item;
        const regex = new RegExp(`\\b(${matchedWinery.name})\\b`, 'gi');
        response = response.replace(regex, matchedWinery.name);

        if (/address/i.test(userMessage)) {
            response = `${matchedWinery.name}: ${matchedWinery.address}`;
        }
      }

      setChat(prevChat => prevChat.map((msg, idx) => idx === prevChat.length - 1 ? { ...msg, content: response } : msg));
    } catch (error) {
      console.error("Error creating completion:", error);
      response = "Sorry, there was an error processing your request. Please try again later.";

      setChat(prevChat => prevChat.map((msg, idx) => idx === prevChat.length - 1 ? { ...msg, content: response } : msg));
    }
  };

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chat]);

  // Handle click outside to close the chat
  useEffect(() => {
    function handleClickOutside(event) {
      if (chatBoxRef.current && !chatBoxRef.current.contains(event.target) && !event.target.closest('.MuiIconButton-root')) {
        setIsOpen(false);
      }
    }

    // Add event listener to detect clicks outside the chat box
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Clean up the event listener on component unmount
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [chatBoxRef]);

  return (
    <Box sx={{ position: 'fixed', bottom: 35, right: 55, zIndex: 3000 }}>
      <IconButton
        color="primary"
        onClick={() => setIsOpen(!isOpen)}
        sx={{ 
          backgroundColor: '#7b1fa2', 
          '&:hover': { backgroundColor: '#682a75' }, 
          borderRadius: '50%', 
          padding: 4, 
          width: 70, 
          height: 70 
        }}
        aria-label="Toggle chat"
      >
        <ChatIcon sx={{ color: '#ffffff', fontSize: 40 }} /> 
      </IconButton>
      {isOpen && (
        <Box
          ref={chatBoxRef} // Attach ref to the chat box
          sx={{ position: 'fixed', bottom: 125, right: 55, mt: 2, p: 0, borderRadius: '16px', backgroundColor: '#fff', width: '30vw', minWidth: '350px', height: '70vh', boxShadow: 5, display: 'flex', flexDirection: 'column' }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 0, boxShadow: .5, borderRadius: '8px 8px 0 0', overflow: 'hidden', zIndex: 3000, height: '80px', minHeight: '80px'  }}>
            <img src="/assets/fincuvaAI.png" alt="fincuva AI" style={{ width: '220px', marginTop: '30px', marginBottom: '30px' }} />
          </Box>
          <Box sx={{ flexGrow: 1, overflowY: 'auto', mb: 2, p: 2 }}>
            {chat.map((msg, index) => (
              <Box key={index} sx={{ mb: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Box sx={{ backgroundColor: msg.role === 'user' ? '#f7f2f7' : '#f3e5f5', borderRadius: '30px', p: 2, paddingTop: 0, paddingBottom: 0, mb: 3, maxWidth: '80%', alignSelf: msg.role === 'user' ? 'flex-end' : 'flex-start', marginRight: msg.role === 'user' ? '8px' : '0' }}>
                  <Typography variant="body1">
                    <ReactMarkdown>{msg.content}</ReactMarkdown>
                  </Typography>
                </Box>
              </Box>
            ))}
            <div ref={chatEndRef} />
          </Box>
          <TextField
            variant="outlined"
            fullWidth
            placeholder="Ask about Michigan wines..."
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleSend();
              }
            }}
            autoComplete="off"
            sx={{
              fontFamily: 'Roboto',
              p: 2,
              '& .MuiOutlinedInput-root': {
                borderRadius: '16px',
                '& fieldset': {
                  borderColor: '#ddc5e3'
                },
                '&:hover fieldset': {
                  borderColor: '#ddc5e3'
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#ddc5e3'
                }
              }
            }}
            aria-label="User input"
          />
        </Box>
      )}
    </Box>
  );
};

export default ChatBot;
