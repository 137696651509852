import React, { useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { styled } from '@mui/system';
import bridgeImage from '../../assets/bridge.jpg';
import FennvilleGrowingConditions from './FennvilleGrowingConditions';
import LmsMustTryBottles from './LmsMustTryBottles';
import LmsWineries from './FennvilleWineries';
import FennvilleInterestingVarieties from './FennvilleInterestingVarieties';
import { useDrawer } from '../../context/DrawerContext';

const PageContainer = styled(Box)(({ drawerOpen }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // width: '100%',
    // maxWidth: `calc(100vw - ${drawerOpen ? 340 : 340}px)`, // Dynamically calculate width
    height: 'auto',
    margin: '0',
    paddingTop: '2rem',
    marginLeft: drawerOpen ? '435px' : '300px', // Adjust padding to account for the drawer
    backgroundColor: '#ffffff',
    boxSizing: 'border-box',
    marginTop: '20px',
    marginBottom: '40px',
}));

const HeroBox = styled(Box)(({ isSticky }) => ({
    width: '1100px',
    height: '250px',
    backgroundImage: `url(${bridgeImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center 70%',
    backgroundRepeat: 'no-repeat',
    marginBottom: '16px',
    borderRadius: '20px',
    position: 'relative',
    top: 'auto',
    transition: '0.05s ease',
    zIndex: 1,
    overflow: 'hidden',
    maxWidth: '1200px',
    margin: '0 auto',
    marginTop: '-100px',
    // '::after': {
    //     content: '""',
    //     position: 'absolute',
    //     bottom: 0,
    //     left: 0,
    //     width: '100%',
    //     height: '30%',
    //     background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))',
    // },
}));

const SectionContainer = styled(Box)({
    backgroundColor: '#ffffff',
    width: '1100px',
    maxWidth: '100vw',
    margin: '40px auto 40px auto',
    padding: '16px',
});

const TitleTypography = styled(Typography)({
    color: '#7b1fa2',
    textAlign: 'center',
    marginBottom: '70px',
    fontFamily: "'Playfair Display', serif",
    fontWeight: 'bold',
});

const RightAlignedTitleTypography = styled(TitleTypography)({
    color: '#7b1fa2',
    marginBottom: '40px',
    fontFamily: "'Playfair Display', serif",
    fontWeight: 'bold',
    textAlign: 'right', // Align the text to the right
    marginRight: '27%', // Adjust the margin to position the text as needed
});

const FennvilleAVADetails = () => {
    const [isSticky, setIsSticky] = useState(true);
    const { drawerOpen } = useDrawer();

    return (
        <PageContainer maxWidth={false} disableGutters drawerOpen={drawerOpen}>
            <HeroBox isSticky={isSticky} />
            <Box sx={{ marginTop: '1rem', width: '80%' }}>
                <SectionContainer>
                    <TitleTypography variant="h1">Overview & Growing Conditions</TitleTypography>
                    <FennvilleGrowingConditions />
                </SectionContainer>
                <SectionContainer>
                    <TitleTypography variant="h1">Wineries</TitleTypography>
                    <LmsWineries />
                </SectionContainer>
                <SectionContainer>
                    <TitleTypography variant="h1" sx={{ marginTop: '20px' }}>Interesting Varieties</TitleTypography>
                    <FennvilleInterestingVarieties />
                </SectionContainer>
                <SectionContainer>
                    <TitleTypography variant="h1">Must Try Bottles</TitleTypography>
                    <LmsMustTryBottles />
                </SectionContainer>
            </Box>
        </PageContainer>
    );
};

export default FennvilleAVADetails;
