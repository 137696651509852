// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { DrawerProvider } from './context/DrawerContext'; // import DrawerProvider
import Layout from './components/Navigation/Layout';
import theme from './theme';
import HomePage from './pages/Home/HomePage';
import GrapeVarieties from './pages/GrapeVarieties/GrapeVarieties';
import AVAs from './pages/AVAs/AVAs';
import Wineries from './pages/Wineries/Wineries';
import FennvilleAVADetails from './pages/AVAs/FennvilleAVADetails';
import LakeMichiganShoreAVADetails from './pages/AVAs/LmsAVADetails';
import MapPage from './pages/MapPage';
import ChatBot from './components/ChatBot';
import { isMobileDevice } from './utils/detectDevice'; // Import the device detection utility
import { Box, Typography } from '@mui/material';

function App() {
    const isMobile = isMobileDevice();

    return (
        <ThemeProvider theme={theme}>
            {isMobile ? (
                <Box
                    sx={{
                        height: '80vh',
                        display: 'fit',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        padding: '2rem',
                    }}
                >
                    <a href="https://www.fincuva.com/" target="_blank" rel="noopener noreferrer">
                        <img
                            src="/assets/logo.png"
                            alt="Fincuva Logo"
                            style={{
                                maxWidth: '100%',
                                maxHeight: '50%',
                                height: 'auto',
                                marginBottom: '2rem',
                            }}
                        />
                    </a>
                    <Typography variant="h4" component="h1" gutterBottom sx={{ fontSize: '1.8rem' }}>
                        The fincuva team is hard at work on the Southwest Michigan Wine mobile app.
                        For now, the site is best viewed on desktop.
                    </Typography>
                </Box>
            ) : (
                <Router>
                    <DrawerProvider> {/* Provide the Drawer context */}
                        <Layout>
                            <Routes>
                                <Route path="/" element={<HomePage />} />
                                <Route path="/grape-varieties" element={<GrapeVarieties />} />
                                <Route path="/avas" element={<AVAs />} />
                                <Route path="/avas/fennville" element={<FennvilleAVADetails />} />
                                <Route path="/avas/lake-michigan-shore" element={<LakeMichiganShoreAVADetails />} />
                                <Route path="/wineries/:id" element={<Wineries />} />
                                <Route path="/map" element={<MapPage />} />
                            </Routes>
                        </Layout>
                    </DrawerProvider>
                    <ChatBot />
                </Router>
            )}
        </ThemeProvider>
    );
}

export default App;
