import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, GeoJSON, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import lakeMichiganShoreData from '../assets/lake_michigan_shore_ava.json'; // Import Lake Michigan Shore AVA JSON file
import fennvilleData from '../assets/fennville_ava.json'; // Import Fennville AVA JSON file
import wineryData from '../data/wineryData.json'; // Import winery data JSON file
import { FormGroup, FormControlLabel, Switch, Box } from '@mui/material'; // Import MUI components
import WineryCard from '../components/WineryCard'; // Import WineryCard component
import iconWineUrl from '../assets/icon_wine.svg'; // Import custom wine icon
import './styles.css'; // Import the CSS file
import { useDrawer } from '../context/DrawerContext';

const createCustomIcon = (color) => {
    return new L.Icon({
        iconUrl: iconWineUrl,
        iconSize: [52, 52], // Adjust size as needed
        iconAnchor: [21, 25], // Center of the icon, moved down by 20%
        popupAnchor: [7, -22],
        className: `custom-icon-${color}`
    });
};

const CustomZoomControl = ({ zoomLevel }) => {
    const map = useMap();

    useEffect(() => {
        if (map) {
            map.setZoom(zoomLevel);
        }
    }, [zoomLevel, map]);

    return null;
};

const position = [42.22, -86.05]; // Center of the AVA

const MapPage = () => {
    const { drawerOpen } = useDrawer();
    const [showLakeMichiganShore, setShowLakeMichiganShore] = useState(true);
    const [showFennville, setShowFennville] = useState(true);
    const [activeMarker, setActiveMarker] = useState(null);
    const [zoomLevel, setZoomLevel] = useState(9.6); // Initial zoom level

    return (
        <div className="map-wrapper">
            <MapContainer
                center={position}
                zoom={zoomLevel}
                scrollWheelZoom={true} // Enable zoom with scroll wheel
                className="map-container"
            >
                <TileLayer
                    url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
                    attribution='Map AVA data &copy; <a href="https://www.fincuva.com">fincuva</a>, Imagery © <a href="https://carto.com/attributions">CARTO</a>'
                />
                {showLakeMichiganShore && (
                    <GeoJSON
                        data={lakeMichiganShoreData}
                        style={{
                            color: '#d9c3de', // Border color
                            weight: 4,
                            fillOpacity: 0 // Ensure no fill
                        }}
                    />
                )}
                {showFennville && (
                    <GeoJSON
                        data={fennvilleData}
                        style={{
                            color: '#d9c3de', // Border color
                            weight: 4,
                            fillOpacity: 0 // Ensure no fill
                        }}
                    />
                )}
                {wineryData.map((marker, index) => (
                    <Marker
                        key={index}
                        position={marker.position}
                        icon={createCustomIcon(activeMarker === index ? 'hovered' : 'default')}
                        eventHandlers={{
                            click: () => setActiveMarker(index),
                            mouseover: () => setActiveMarker(index),
                            mouseout: () => setActiveMarker(null),
                        }}
                    >
                        <Popup>
                            <WineryCard
                                name={marker.name}
                                location={marker.address}
                                description={marker.description}
                                image={marker.image} // Pass the image path here
                                small
                            />
                        </Popup>
                    </Marker>
                ))}
                <CustomZoomControl zoomLevel={zoomLevel} />
                <Box
                    sx={{
                        position: 'absolute',
                        top: 16,
                        right: 16,
                        zIndex: 1000,
                        backgroundColor: 'rgba(255, 255, 255, 0.7)', // white with 50% transparency
                        padding: 1,
                        borderRadius: 4
                    }}
                >
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch
                                checked={showLakeMichiganShore}
                                onChange={(e) => setShowLakeMichiganShore(e.target.checked)}
                                sx={{ '& .MuiSwitch-switchBase.Mui-checked': { color: '#d9c3de' }, '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': { backgroundColor: '#f1e1f5' } }}
                            />}
                            label="Lake Michigan Shore AVA"
                        />
                        <FormControlLabel
                            control={<Switch
                                checked={showFennville}
                                onChange={(e) => setShowFennville(e.target.checked)}
                                sx={{ '& .MuiSwitch-switchBase.Mui-checked': { color: '#d9c3de' }, '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': { backgroundColor: '#f1e1f5' } }}
                            />}
                            label="Fennville AVA"
                        />
                    </FormGroup>
                </Box>
            </MapContainer>
        </div>
    );
};

export default MapPage;
