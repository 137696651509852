import React, { useState } from 'react';
import { Container, Grid, Typography, Dialog, DialogContent, Card, Link, Box } from '@mui/material';
import VarietyCard from '../../components/VarietyCard';
import LargeVarietyCard from '../../components/LargeVarietyCard';
import grapeVarieties from '../../data/lms_varieties.json';
import { styled } from '@mui/system';
import GrapeCharts from './GrapeCharts';
import { useDrawer } from '../../context/DrawerContext';
import { isMobileDevice } from '../../utils/detectDevice'; // Import your device detection utility

const PageContainer = styled(Box)(({ drawerOpen }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: `calc(100vw - ${drawerOpen ? 340 : 340}px)`, // Keep desktop styles unchanged
    height: 'auto',
    margin: '0',
    paddingTop: '1rem',
    marginLeft: drawerOpen ? '340px' : '170px', // Keep desktop styles unchanged
    backgroundColor: '#ffffff',
    boxSizing: 'border-box',
    marginTop: '20px',
    marginBottom: '40px',
}));

const BackgroundContainer = styled(Container)(({ theme }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '2rem',
    paddingLeft: '0rem',
    backgroundColor: '#ffffff',
    boxSizing: 'border-box',
    width: '1100px', // Keep desktop styles unchanged
}));

const TitleContainer = styled('div')({
    textAlign: 'center',
    marginBottom: '3rem',
});

const TitleTypography = styled(Typography)({
    color: '#7b1fa2',
    textAlign: 'center',
    marginBottom: '20px',
    fontFamily: "'Playfair Display', serif",
    fontWeight: 'bold',
});

const StyledDialog = styled(Dialog)({
    '& .MuiPaper-root': {
        borderRadius: '16px',  // Adjust the value to increase the border radius
        maxWidth: '600px',     // Match the card's width
        width: '100%',         // Ensure the dialog takes full width within the max-width constraint
    },
});

const ChartsContainer = styled('div')({
    display: 'flex',
    justifyContent: 'space-around',
    width: '1200px',
    marginBottom: '2rem',
});

const ChartBox = styled(Card)({
    width: '1200px',
    padding: '1rem',
    marginTop: '30px',
    backgroundColor: '#ffffff',
    borderRadius: '16px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
});

const ChartContainer = styled('div')({
    width: '100%',
    height: '550px',  // Adjust height as needed
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
});

const StyledLink = styled(Link)({
    color: 'lightgrey',
    textAlign: 'center',
    display: 'block',
    marginTop: '2rem',
    fontSize: '1.1rem',
    textDecoration: 'none', // This ensures the text is not underlined
});

const GrapeVarieties = () => {
    const [selectedVariety, setSelectedVariety] = useState(null);
    const { drawerOpen } = useDrawer();
    const isMobile = isMobileDevice(); // Use the device detection utility

    const handleCardClick = (variety) => {
        setSelectedVariety(variety);
    };

    const handleClosePopup = () => {
        setSelectedVariety(null);
    };

    return (
        <PageContainer drawerOpen={drawerOpen}>
            <BackgroundContainer>
                <TitleContainer>
                    <Typography variant="h4" component="h2">
                        Southwest Michigan Grape Varieties
                    </Typography>
                </TitleContainer>
                {/* <Typography 
                    variant="body1" 
                    component="p" 
                    paragraph
                    sx={{
                        fontSize: isMobile ? '14px' : '20px', // Adjust font size based on device
                    }}
                >
                    We tend to think that hybrids should come first. In both Fennville and the Lake Michigan Shore, hybrid grape varieties are growing in popularity because they truly deserve to be a large part of the future of the region’s winemaking. These grapes have been carefully developed to handle Michigan’s unique climate, with cold winters, unpredictable growing seasons, and sometimes rainy conditions. Unlike traditional European varieties, hybrids are naturally more resistant to the cold and local diseases, making them a perfect match for the AVAs. But it's not just about hardiness qualities—hybrid grapes result in incredible wine flavors, both original and common to vinifera wines. Varieties like Baco Noir and Frontenac can offer rich, complex profiles that reflect the region’s terroir in a way that’s not always possible with classic varieties. By focusing on hybrids, we’re embracing the qualities that truly make Michigan wines stand out—resilience, innovation, and a deep respect for the land.
                </Typography> */}
                <Typography 
                    variant="body1" 
                    component="p" 
                    paragraph
                    sx={{
                        fontSize: isMobile ? '14px' : '20px', // Adjust font size based on device
                    }}
                >
                    Both vinifera and hybrid varieties are currently making world-class wines in Southwest Michigan. All statistics below are showing total Michigan acreage by variety as of 2020. In that year, Southwest Michigan accounted for 79% of total planted vineyard acres in the state.
                </Typography>
                {!isMobile && ( // Hide charts on mobile
                    <ChartsContainer>
                        <ChartBox>
                            <TitleTypography variant="h5" component="div" gutterBottom>
                                Vinifera Grapes by Acreage
                            </TitleTypography>
                            <ChartContainer>
                                <GrapeCharts category="VINIFERA" />
                            </ChartContainer>
                            <StyledLink href="https://www.nass.usda.gov/Statistics_by_State/Michigan/Publications/Michigan_Rotational_Surveys/mi_fruit20/Grapes%20hops.pdf" target="_blank" rel="noopener noreferrer">
                                2020 Michigan Rotational Surveys
                            </StyledLink>
                        </ChartBox>
                    </ChartsContainer>
                )}
                <Grid container spacing={2} sx={{ justifyContent: isMobile ? 'center' : 'flex-start', marginTop: isMobile ? '2px' : '0' }}>
                    {grapeVarieties
                        .filter(variety => !variety.species.toLowerCase().includes('hybrid'))
                        .map((variety, index) => (
                            <Grid item xs={10} sm={6} md={4} key={index} sx={{ 
                                transform: isMobile ? 'scale(0.5)' : 'none', // Scale down cards to 50% on mobile
                                transformOrigin: '0 0',
                                width: isMobile ? '100%' : 'auto', // Ensure the grid takes full width on mobile
                                marginRight: isMobile ? '-210px' : '0',
                                marginBottom: isMobile ? '-190px' : '0',
                                justifyContent: 'center',
                            }}>
                                <VarietyCard 
                                    name={variety.variety} 
                                    image={variety.image} 
                                    otherNames={variety.other_names}
                                    typicalStyle={variety.typical_style}
                                    species={variety.species}
                                    color={variety.color}
                                    onClick={() => handleCardClick(variety)}
                                />
                            </Grid>
                        ))}
                </Grid>
                {!isMobile && ( // Hide charts on mobile
                    <ChartsContainer>
                        <ChartBox>
                            <TitleTypography variant="h5" component="div" gutterBottom>
                                Hybrid Grapes by Acreage
                            </TitleTypography>
                            <ChartContainer>
                                <GrapeCharts category="HYBRIDS" />
                            </ChartContainer>
                            <StyledLink href="https://www.nass.usda.gov/Statistics_by_State/Michigan/Publications/Michigan_Rotational_Surveys/mi_fruit20/Grapes%20hops.pdf" target="_blank" rel="noopener noreferrer">
                                2020 Michigan Rotational Surveys
                            </StyledLink>
                        </ChartBox>
                    </ChartsContainer>
                )}
                <Grid container spacing={2} sx={{ justifyContent: isMobile ? 'center' : 'flex-start' }}>
                    {grapeVarieties
                        .filter(variety => variety.species.toLowerCase().includes('hybrid'))
                        .map((variety, index) => (
                            <Grid item xs={10} sm={6} md={4} key={index} sx={{ 
                                transform: isMobile ? 'scale(0.5)' : 'none', // Scale down cards to 50% on mobile
                                transformOrigin: '0 0',
                                width: isMobile ? '100%' : 'auto', // Ensure the grid takes full width on mobile
                                marginRight: isMobile ? '-210px' : '0',
                                marginBottom: isMobile ? '-190px' : '0',
                                justifyContent: 'center',
                            }}>
                                <VarietyCard 
                                    name={variety.variety} 
                                    image={variety.image} 
                                    otherNames={variety.other_names}
                                    typicalStyle={variety.typical_style}
                                    species={variety.species}
                                    color={variety.color}
                                    onClick={() => handleCardClick(variety)}
                                />
                            </Grid>
                        ))}
                </Grid>
                {selectedVariety && (
                    <StyledDialog 
                        open={Boolean(selectedVariety)} 
                        onClose={handleClosePopup}
                        maxWidth="md"
                        fullWidth
                    >
                        <DialogContent>
                            <LargeVarietyCard variety={selectedVariety} />
                        </DialogContent>
                    </StyledDialog>
                )}
            </BackgroundContainer>
        </PageContainer>
    );
};

export default GrapeVarieties;
