import React, { useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { styled } from '@mui/system';
import sunsetImage from '../../assets/sunset.jpg';
import LmsHistory from './LmsHistory';
import LmsGrowingConditions from './LmsGrowingConditions';
import LmsMustTryBottles from './LmsMustTryBottles';
import LmsWineries from './LmsWineries';
import LmsInterestingVarieties from './LmsInterestingVarieties';
import { useDrawer } from '../../context/DrawerContext';

const PageContainer = styled(Box)(({ drawerOpen }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // width: '1200px',
    // maxWidth: `calc(100vw - ${drawerOpen ? 380 : 340}px)`, // Dynamically calculate width
    height: 'auto',
    margin: '0',
    paddingTop: '2rem',
    marginLeft: drawerOpen ? '420px' : '300px', // Adjust padding to account for the drawer
    backgroundColor: '#ffffff',
    boxSizing: 'border-box',
    marginTop: '20px',
    marginBottom: '40px',
}));


const HeroBox = styled(Box)(({ isSticky }) => ({
    width: '1100px',
    height: '250px',
    backgroundImage: `url(${sunsetImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center 70%',
    backgroundRepeat: 'no-repeat',
    marginBottom: '16px',
    borderRadius: '20px',
    position: isSticky ? 'sticky' : 'relative',
    top: isSticky ? '0' : 'auto',
    transition: '0.05s ease',
    zIndex: 1,
    overflow: 'hidden',
    maxWidth: '1200px',
    margin: '0 auto',
    marginTop: '-120px',
}));


const SectionContainer = styled(Box)({
    backgroundColor: '#ffffff',
    width: '1100px',
    maxWidth: '100vw',
    margin: '40px auto 40px auto',
    padding: '16px',
});

const TitleTypography = styled(Typography)({
    color: '#7b1fa2',
    textAlign: 'center',
    marginBottom: '40px',
    fontFamily: "'Playfair Display', serif",
    fontWeight: 'bold',
});

const RightAlignedTitleTypography = styled(TitleTypography)({
    color: '#7b1fa2',
    marginBottom: '40px',
    fontFamily: "'Playfair Display', serif",
    fontWeight: 'bold',
    textAlign: 'right', // Align the text to the right
    marginRight: '11.5%', // Adjust the margin to position the text as needed
});

const LmsAVADetails = () => {
    const { drawerOpen } = useDrawer();
    const [isSticky, setIsSticky] = useState(false);

    return (
        <PageContainer maxWidth={false} disableGutters drawerOpen={drawerOpen}>
            <HeroBox isSticky={isSticky} />
            <Box sx={{ marginTop: '1rem', width: '83%' }}>
                <SectionContainer>
                    <RightAlignedTitleTypography variant="h1" >History & Overview</RightAlignedTitleTypography>
                    <LmsHistory />
                </SectionContainer>
                <SectionContainer>
                    <TitleTypography variant="h1">Growing Conditions</TitleTypography>
                    <LmsGrowingConditions />
                </SectionContainer>
                <SectionContainer>
                    <TitleTypography variant="h1">Wineries</TitleTypography>
                    <LmsWineries />
                </SectionContainer>
                <SectionContainer>
                    <TitleTypography variant="h1" sx={{ marginTop: '20px' }}>Interesting Varieties</TitleTypography>
                    <LmsInterestingVarieties />
                </SectionContainer>
                <SectionContainer>
                    <TitleTypography variant="h1">Must Try Bottles</TitleTypography>
                    <LmsMustTryBottles />
                </SectionContainer>
            </Box>
        </PageContainer>
    );
};

export default LmsAVADetails;
